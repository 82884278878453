<template>
  <div>
    <c-table
      ref="table"
      :title="`${popupParam.date} 일상점검결과`"
      :columns="grid.columns"
      :merge="grid.merge"
      :gridHeight="grid.height"
      :data="filteringData"
      :editable="editable&&!disabled"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
    >
      <template v-slot:customArea="{ props, col }">
        <component
          v-if="col.name === 'customCol'"
          :is="imprComponent"
          :col="col"
          :props="props"
          :inputEditable="editable&&!disabled"
          :isImmShow="true"
          :requestContentsCols="requestContentsCols"
          tableKey="sopPreWorkCheckTargetResultId"
          ibmTaskTypeCd="ITT0000195"
          ibmTaskUnderTypeCd="ITTU000221"
          @imprChange="imprChange"
        />
      </template>
      <template slot="customFilter">
        <c-radio
          :comboItems="filterItems"
          label=""
          name="filter"
          v-model="filter"
        />
      </template>
      <template slot="table-header-append">
        <div style="padding-top: 7px;font-size: 1.1em;font-weight: 600;">점검자 </div>
        <c-field
          :disabled="disabled"
          :editable="editable"
          class="preWorkDaliyCheckUser"
          name="userId"
          :placeholder="$label('LBL0002218')"
          v-model="target.userId">
        </c-field>
      </template>
      <template slot="table-button">
        <q-btn-group outline>
          <c-appr-btn 
            ref="appr-btn"
            :editable="editable"
            :approvalInfo="approvalInfo"
            @beforeApprAction="approvalPreWorkCheck"
            @callbackApprAction="approvalPreWorkCheckCallback"
            @requestAfterAction="getDetail"
          />
          <!-- 저장 -->
          <c-btn
            v-if="editable&&!disabled"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="target"
            mappingType="PUT"
            label="LBLSAVE"
            icon="save"
            @beforeAction="saveInfo"
            @btnCallback="saveInfoCallback" />
        </q-btn-group>
      </template>
    </c-table>
    <c-upload 
      style="margin-top: 8px !important;"
      :attachInfo="attachInfo"
      :editable="editable&&!disabled"
      label="첨부파일">
    </c-upload>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'preWorkCheckTargetDayResult',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          sopPreWorkCheckId: '',
          yearmonth: '',
          date: '',
          disabled: false,
          apprCycle: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      target: {
        sopPreWorkCheckTargetId: '',  // 작업 전 안전점검 대상 일련번호
        sopPreWorkCheckId: '',  // 작업 전 안전점검 일련번호
        deptCd: '',  // 점검부서
        deptName: '',  // 점검부서
        userId: '',  // 점검자
        userName: '',  // 점검자
        yearmonth: '',  // 년월
        checkDate: '',  // 점검일
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        results: [],
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
      },
      grid: {
        merge: [
          { index: 0, colName: 'sopPreWorkCheckClassCd' },
          { index: 1, colName: 'checkItemName' },
        ],
        columns: [
          {
            name: 'sopPreWorkCheckClassName',
            field: 'sopPreWorkCheckClassName',
            label: '점검구분',
            align: 'center',
            sortable: false,
            style: 'width:120px',
          },
          {
            name: 'checkItemName',
            field: 'checkItemName',
            label: '점검항목명',
            align: 'left',
            sortable: false,
            style: 'width:150px',
          },
          {
            name: 'checkItemSubName',
            field: 'checkItemSubName',
            label: '점검세부항목명',
            align: 'left',
            sortable: false,
            style: 'width:300px',
          },
          {
            required: true,
            name: 'pwcResultCd',
            field: 'pwcResultCd',
            label: '점검결과',
            align: 'center',
            sortable: false,
            style: 'width:100px',
            type: 'select',
            codeGroupCd: 'PWC_RESULT_CD',
            setHeader: true,
            headType: 'select',
          },
          {
            name: 'addInfo',
            field: 'addInfo',
            label: '부가정보',
            align: 'left',
            sortable: false,
            style: 'width:100px',
            type: 'text',
            suffixCol: 'unit'
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: 'LBLIMPRNO_PROGRESS',
            align: 'center',
            style: 'width:350px',
            type: 'custom',
            sortable: false
          },
        ],
        height: '500px'
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'SOP_PRE_WORK_TARGET',
        taskKey: '',
      },
      filter: 'A',
      filterItems: [
        { code: 'A', codeName: '전체보기', },
        { code: 'M', codeName: '할당된 항목 보기', },
      ],
      editable: true,
      isSave: false,
      isApproval: false,
      detailUrl: '',
      saveUrl: '',
      approvalUrl: '',
    };
  },
  computed: {
    // 개선요청 내용 전달
    requestContentsCols() {
      return ['sopPreWorkCheckClassName', 'checkItemName', 'checkItemSubName'];
    },
    // 개선요청 Component
    imprComponent() {
      return () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
    },
    filteringData() {
      let items = [];
      if (this.target.results && this.target.results.length > 0) {
        if (this.filter === 'M') {
          // 나에게 할당된 점검항목 filtering
          items = this.$_.filter(this.target.results, (result) => {
            return Boolean(result.userId) && result.userId.indexOf(this.$store.getters.user.userId) > -1
          })
        } else {
          // 전체보기
          items = this.target.results;
        }
      }
      return items;
    },
    approvalInfo() {
      return {
        sysApprovalRequestId: this.target.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.target.approvalStatusCd, 
        apprEditable: !this.target.sysApprovalRequestId
          && this.popupParam.apprCycle === 'D'
          && !this.popupParam.disabled, // 결재버튼 활성화 기준
        param: this.target, // 결재 param
        approvalUrl: this.approvalUrl, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000033', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          sopPreWorkCheckTargetId: this.target.sopPreWorkCheckTargetId,
          isApprContent: true
        },
        approvalRequestName: `[${this.target.deptName}] ${this.target.checkDate} 일상점검`, // 결재요청명 (문서 title)
        approvalConnId: this.target.sopPreWorkCheckTargetId, // 결재연결용 업무일련번호 (문서 key)
      }
    },
    disabled() {
      return this.popupParam.disabled
        || Boolean(this.target.sysApprovalRequestId)
    }
  },
  watch: {
    // contentHeight() {
    //   let height = this.contentHeight - 150;
    //   if (height < 500) {
    //     height = 500;
    //   }
    //   this.grid.height = String(height) + 'px';
    // }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.pwc.target.get.url
      this.saveUrl = transactionConfig.sop.pwc.target.update.url
      this.approvalUrl = transactionConfig.sop.pwc.target.update.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      this.$http.url = this.$format(this.detailUrl, this.popupParam.sopPreWorkCheckId, this.popupParam.date);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.target, _result.data);

        console.log(this.$_.clone(this.target))
        this.$set(this.attachInfo, 'taskKey', this.target.sopPreWorkCheckTargetId)
      },);
    },
    saveInfo() {
      if (this.$comm.validTable(this.grid.columns, this.filteringData)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.target.chgUserId = this.$store.getters.user.userId
            
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveInfoCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    imprChange() {
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
    },
    approvalPreWorkCheck() {
      if (this.$comm.validTable(this.grid.columns, this.filteringData)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: '결재요청 하기 전 입력된 값을 저장합니다.\n진행하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.target.chgUserId = this.$store.getters.user.userId
            
            this.isApproval = !this.isApproval
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    approvalPreWorkCheckCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
  }
};
</script>
<style lang="sass">
.preWorkDaliyCheckUser
  min-width: 150px
  max-width: 250px
  padding-bottom: 0px !important
  .q-field
    padding: 0 !important
</style>